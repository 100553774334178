html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, 
hgroup, menu, nav, section, menu,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}                  

article, aside, figure, footer, header, 
hgroup, nav, section { display:block; }

nav ul { list-style:none; }

blockquote, q { quotes:none; }

blockquote:before, blockquote:after,
q:before, q:after { content:''; content:none; }

a { margin:0; padding:0; font-size:100%; vertical-align:baseline; background:transparent; }

ins { background-color:#ff9; color:#000; text-decoration:none; }

mark { background-color:#ff9; color:#000; font-style:italic; font-weight:bold; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom:1px dotted #000; cursor:help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse:collapse; border-spacing:0; }

hr { display:block; height:1px; border:0; border-top:1px solid #212121; margin:1em 0; padding:0; clear:both;}

input, select { vertical-align:middle; }

/*links*/
a:hover, a:active { outline: none; }

a, a:active{ color:#f47c30; text-decoration:none;}

a:hover { color:#fff; }

/*lists*/
ul { margin:10px 0 30px 10px; }
ol { margin:10px 0 30px 10px; list-style-type: decimal; }

/*typography*/
body {
  font:12px/18px  Arial, Helvetica, sans-serif; color:#fff;
}

h1, h2, h3, h4, h5, p {
  margin-bottom:20px;
  font-weight:400;
}

/*clear*/
.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; } /* Hides from IE-mac \*/
.clearfix { display: block; }


