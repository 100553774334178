.huge_it_slideshow_dots_thumbnails_2 {display:none;}

body {
	background: #0d0d0d url('../../user/themes/miu/img/bg.gif') repeat;
}

input, textarea {
	background-color:#000000;
	border:1px solid #212121;
	color:#fff;
	padding:3px;
	font-family:Arial, Helvetica, sans-serif;
	font-size:11px;
}

.content-entry ul,
dl, 
p, 
table {
	font-family:Arial, Helvetica, sans-serif;
}

input.wpcf7-submit {
	background-color:#fff;
	border:1px solid #212121;
	color:#000;
	font-weight:bold;
}

#container {
	max-width: 1000px;
	padding:20px;
	margin:0 auto;

}

figure {
	padding:20px 0;
	border-bottom:1px solid #212121;
	position:relative;
}

figure figcaption{
	position:absolute;
	top:100px;
	left:20px;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size:26px;
	text-shadow:1px 1px #000;
}

ul#sliderwrap, ul#sliderwrap li {
	list-style:none;
	margin:0px;
	padding:0px;
}

#main {
	border-bottom:1px solid #212121;
	min-height:400px;

	@include respond-min($gamma) {
		display: flex;
	}
}

#main section#primary {
	width: 100%;
	float:left;
	padding:20px 0;

	@include respond-min($gamma) {
		width:620px;
	}
}

#main section#primary h2, #main section#primary h1{
	color:#f47c30;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size:22px;
}

#main section#primary h3{
	color:#f47c30;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size:18px;
}

#main section#primary article {
	margin-bottom:40px;
}

#main section#primary .coloumn{
	float:left;
	width:280px;
}

#main section#sidebar {
	width: 100%;
	float:left;
	min-height:400px;
	padding:20px;
	background-color: rgba(255,255,255,.05);

	@include respond-min($gamma) {
		width:309px;
		margin-left:30px;
	}
}

#facebook {
	overflow: hidden;
}

#main section#sidebar .widget {
	margin-bottom:30px;
}

#main section#sidebar h2 {
	color:#f47c30;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size:18px;
}

#main section#sidebar h3, #main section#sidebar dl dt, #main section#sidebar dl dd {
	color:#fff;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size:16px;
}

#main section#sidebar dl dt{
	float:left;
	display:block;
	clear:left;
	margin-bottom:10px;
}

#main section#sidebar dl dd{
	float:right;
	display:block;
	clear:right;
	margin-bottom:10px;
}

#main img.alignleft {
	float:left;
	margin:0 20px 10px 0;
}

#main section#sidebar dl {
	clear:both;
}

footer {
	padding:20px 0 0 0;
}

footer p {
	float:right;
}

.hotspot {color:#900; padding-bottom:1px;  cursor:pointer; width:20px;}

#tt {position:absolute; display:block; }

#tttop {display:block; height:2px; margin-left:5px;  overflow:hidden;}

#ttcont {
	padding:40px 19px 17px 23px;
	margin-left:7px;
	width:160px;
	font-weight:bold;
	background-image: url(../../user/themes/miu/img/flame1.png);
	background-repeat: no-repeat;
}/*Change font color to suit*/

#ttbot {display:block; height:7px; margin-left:5px;overflow:hidden;}

#fm {
	width:59px;
	height:42px;
	background-image: url(../../user/themes/miu/img/flame1.png);
	background-repeat: no-repeat;
	margin-right: auto;
	margin-left: auto;
	margin-top: 0px;
	margin-bottom: 15px;
}
#info {
	margin-top: 25px;
}

#fm a{ display:block; width:59px; height:42px; margin:0 auto; text-indent: -9999px; }

img.find {margin:-17px 0 0 0;}

img.princess {float:right; width:250px; height:249px; margin:0 0 0 10px;}

.border {border:1px solid #212121}

p.short {width:350px;}

/* Prices table */
#prices table {font-size:13px; color:#fff;}
.column1, .column4 {width:150px;}
.column2, .column5 {width:32px;}
.column3, .column6 {width:90px;}
.strong.column1, .strong.column4 {height:25px;}

/*	Added by Tony Barrett 28/1/13	*/
.semiPermMakeUp img
{
	border: solid 2px #cfcfcf;
}

.footerText {margin-top:20px; float:left;}

.copyright {float:left;}

.gallery {
  margin: auto;
}
.gallery .gallery-item {
  float: left;
  margin-top: 10px;
  text-align: center;
  width: 33%;
  clear: none !important;
}
.gallery img {
  border: 2px solid #cfcfcf;
}
.gallery .gallery-caption {
  margin-left: 0;
}

iframe {
	max-width: 100%;
}