.bio {
	@include clearfix;
	margin-bottom: $base-spacing-unit;
}
	
	.bio__year,
	.bio__item {
		float: left;
		margin-bottom: $base-spacing-unit / 2;

		@include respond-max($beta) {
			float: none;
		}
	}

	.bio__year {
		font-weight: 700;
		width: 10%;

		@include respond-max($beta) {
			width: 100%;
			margin-bottom: 0;
		}
	}

	.bio__item {
		width: 90%;

		@include respond-max($beta) {
			width: 100%;
		}
	}