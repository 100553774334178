/* ----------------------------------------------

	Based on ITCSS by Harry Roberts
	https://speakerdeck.com/dafed/managing-css-projects-with-itcss

	1. Settings 	- Variables, config.
	2. Tools 		- Mixins, functions
	3. Generic 		- Normalize, reset, * {}
	4. Base 		- Unclassed HTML rules
	5. Objects 		- Cosemetic-free design patterns
	6. Components 	- Chunks of UI
	7. Trumps 		- Helpers and overrides

---------------------------------------------- */
/* ----------------------------------------------
	1. Settings
---------------------------------------------- */
/* names allow us to loop and create class names, e.g. .card--alpha, .ui--beta
------------------------ */
/* ----------------------------------------------
	2. Tools
---------------------------------------------- */
/* Mixins
--------------------------------------------------- */
/* ----------------------------------------------
	3. Generic
---------------------------------------------- */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, menu, time, mark, audio, video { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

article, aside, figure, footer, header, hgroup, nav, section { display: block; }

nav ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

ins { background-color: #ff9; color: #000; text-decoration: none; }

mark { background-color: #ff9; color: #000; font-style: italic; font-weight: bold; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted #000; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse: collapse; border-spacing: 0; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #212121; margin: 1em 0; padding: 0; clear: both; }

input, select { vertical-align: middle; }

/*links*/
a:hover, a:active { outline: none; }

a, a:active { color: #f47c30; text-decoration: none; }

a:hover { color: #fff; }

/*lists*/
ul { margin: 10px 0 30px 10px; }

ol { margin: 10px 0 30px 10px; list-style-type: decimal; }

/*typography*/
body { font: 12px/18px  Arial, Helvetica, sans-serif; color: #fff; }

h1, h2, h3, h4, h5, p { margin-bottom: 20px; font-weight: 400; }

/*clear*/
.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

.clearfix { display: inline-block; }

* html .clearfix { height: 1%; }

/* Hides from IE-mac \*/
.clearfix { display: block; }

* { box-sizing: border-box; }

img { max-width: 100%; }

/*------------------------------------*\ $CLEARFIX
\*------------------------------------*/
/** Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php Extend the clearfix class with Sass to avoid the `.cf` class appearing over and over in your markup. */
.cf:after { content: ""; display: table; clear: both; }

/* endif */
/* ----------------------------------------------
	4. Base
---------------------------------------------- */
/* ----------------------------------------------
	5. Objects
---------------------------------------------- */
.grid { list-style: none; /* [1] */ margin: 0; /* [2] */ padding: 0; /* [2] */ margin-left: -20px; /* [3] */ letter-spacing: -0.31em; }

/* Opera hack */
.opera:-o-prefocus, .grid { word-spacing: -0.43em; }

.grid__item { display: inline-block; /* [1] */ padding-left: 20px; /* [2] */ vertical-align: top; /* [3] */ width: 100%; /* [4] */ -webkit-box-sizing: border-box; /* [5] */ -moz-box-sizing: border-box; /* [5] */ box-sizing: border-box; /* [5] */ letter-spacing: normal; word-spacing: normal; }

.grid--rev { direction: rtl; text-align: left; }
.grid--rev > .grid__item { direction: ltr; text-align: left; }

.grid--full { margin-left: 0; }
.grid--full > .grid__item { padding-left: 0; }

.grid--right { text-align: right; }
.grid--right > .grid__item { text-align: left; }

.grid--center { text-align: center; }
.grid--center > .grid__item { text-align: left; }

.grid--middle > .grid__item { vertical-align: middle; }

.grid--bottom > .grid__item { vertical-align: bottom; }

.grid--narrow { margin-left: -10px; }
.grid--narrow > .grid__item { padding-left: 10px; }

.grid--wide { margin-left: -40px; }
.grid--wide > .grid__item { padding-left: 40px; }

.w-auto { width: auto; }

.one-whole { width: 100%; }

.one-half { width: 50%; }

.one-third { width: 33.333%; }

.two-thirds { width: 66.666%; }

.one-quarter { width: 25%; }

.three-quarters { width: 75%; }

.one-fifth { width: 20%; }

.two-fifths { width: 40%; }

.three-fifths { width: 60%; }

.four-fifths { width: 80%; }

.one-sixth { width: 16.666%; }

.five-sixths { width: 83.333%; }

.one-seventh { width: 14.28571%; }

.one-eighth { width: 12.5%; }

.three-eighths { width: 37.5%; }

.five-eighths { width: 62.5%; }

.seven-eighths { width: 87.5%; }

.one-ninth { width: 11.11111%; }

.one-tenth { width: 10%; }

.three-tenths { width: 30%; }

.seven-tenths { width: 70%; }

.nine-tenths { width: 90%; }

.one-twelfth { width: 8.333%; }

.five-twelfths { width: 41.666%; }

.seven-twelfths { width: 58.333%; }

.eleven-twelfths { width: 91.666%; }

.hide { display: none !important; }

@media only screen and (min-width: 601px) and (max-width: 1023px) { .lap--one-whole { width: 100%; }
  .lap--one-half { width: 50%; }
  .lap--one-third { width: 33.333%; }
  .lap--two-thirds { width: 66.666%; }
  .lap--one-quarter { width: 25%; }
  .lap--three-quarters { width: 75%; }
  .lap--one-fifth { width: 20%; }
  .lap--two-fifths { width: 40%; }
  .lap--three-fifths { width: 60%; }
  .lap--four-fifths { width: 80%; }
  .lap--one-sixth { width: 16.666%; }
  .lap--five-sixths { width: 83.333%; }
  .lap--one-seventh { width: 14.28571%; }
  .lap--one-eighth { width: 12.5%; }
  .lap--three-eighths { width: 37.5%; }
  .lap--five-eighths { width: 62.5%; }
  .lap--seven-eighths { width: 87.5%; }
  .lap--one-ninth { width: 11.11111%; }
  .lap--one-tenth { width: 10%; }
  .lap--three-tenths { width: 30%; }
  .lap--seven-tenths { width: 70%; }
  .lap--nine-tenths { width: 90%; }
  .lap--one-twelfth { width: 8.333%; }
  .lap--five-twelfths { width: 41.666%; }
  .lap--seven-twelfths { width: 58.333%; }
  .lap--eleven-twelfths { width: 91.666%; }
  .lap--hide { display: none !important; } }
@media only screen and (max-width: 1023px) { .portable--one-whole { width: 100%; }
  .portable--one-half { width: 50%; }
  .portable--one-third { width: 33.333%; }
  .portable--two-thirds { width: 66.666%; }
  .portable--one-quarter { width: 25%; }
  .portable--three-quarters { width: 75%; }
  .portable--one-fifth { width: 20%; }
  .portable--two-fifths { width: 40%; }
  .portable--three-fifths { width: 60%; }
  .portable--four-fifths { width: 80%; }
  .portable--one-sixth { width: 16.666%; }
  .portable--five-sixths { width: 83.333%; }
  .portable--one-seventh { width: 14.28571%; }
  .portable--one-eighth { width: 12.5%; }
  .portable--three-eighths { width: 37.5%; }
  .portable--five-eighths { width: 62.5%; }
  .portable--seven-eighths { width: 87.5%; }
  .portable--one-ninth { width: 11.11111%; }
  .portable--one-tenth { width: 10%; }
  .portable--three-tenths { width: 30%; }
  .portable--seven-tenths { width: 70%; }
  .portable--nine-tenths { width: 90%; }
  .portable--one-twelfth { width: 8.333%; }
  .portable--five-twelfths { width: 41.666%; }
  .portable--seven-twelfths { width: 58.333%; }
  .portable--eleven-twelfths { width: 91.666%; }
  .portable--hide { display: none !important; } }
@media only screen and (max-width: 1380px) { .epsilon--one-whole { width: 100%; }
  .epsilon--one-half { width: 50%; }
  .epsilon--one-third { width: 33.333%; }
  .epsilon--two-thirds { width: 66.666%; }
  .epsilon--one-quarter { width: 25%; }
  .epsilon--three-quarters { width: 75%; }
  .epsilon--one-fifth { width: 20%; }
  .epsilon--two-fifths { width: 40%; }
  .epsilon--three-fifths { width: 60%; }
  .epsilon--four-fifths { width: 80%; }
  .epsilon--one-sixth { width: 16.666%; }
  .epsilon--five-sixths { width: 83.333%; }
  .epsilon--one-seventh { width: 14.28571%; }
  .epsilon--one-eighth { width: 12.5%; }
  .epsilon--three-eighths { width: 37.5%; }
  .epsilon--five-eighths { width: 62.5%; }
  .epsilon--seven-eighths { width: 87.5%; }
  .epsilon--one-ninth { width: 11.11111%; }
  .epsilon--one-tenth { width: 10%; }
  .epsilon--three-tenths { width: 30%; }
  .epsilon--seven-tenths { width: 70%; }
  .epsilon--nine-tenths { width: 90%; }
  .epsilon--one-twelfth { width: 8.333%; }
  .epsilon--five-twelfths { width: 41.666%; }
  .epsilon--seven-twelfths { width: 58.333%; }
  .epsilon--eleven-twelfths { width: 91.666%; }
  .epsilon--hide { display: none !important; } }
@media only screen and (max-width: 840px) { .delta--one-whole { width: 100%; }
  .delta--one-half { width: 50%; }
  .delta--one-third { width: 33.333%; }
  .delta--two-thirds { width: 66.666%; }
  .delta--one-quarter { width: 25%; }
  .delta--three-quarters { width: 75%; }
  .delta--one-fifth { width: 20%; }
  .delta--two-fifths { width: 40%; }
  .delta--three-fifths { width: 60%; }
  .delta--four-fifths { width: 80%; }
  .delta--one-sixth { width: 16.666%; }
  .delta--five-sixths { width: 83.333%; }
  .delta--one-seventh { width: 14.28571%; }
  .delta--one-eighth { width: 12.5%; }
  .delta--three-eighths { width: 37.5%; }
  .delta--five-eighths { width: 62.5%; }
  .delta--seven-eighths { width: 87.5%; }
  .delta--one-ninth { width: 11.11111%; }
  .delta--one-tenth { width: 10%; }
  .delta--three-tenths { width: 30%; }
  .delta--seven-tenths { width: 70%; }
  .delta--nine-tenths { width: 90%; }
  .delta--one-twelfth { width: 8.333%; }
  .delta--five-twelfths { width: 41.666%; }
  .delta--seven-twelfths { width: 58.333%; }
  .delta--eleven-twelfths { width: 91.666%; }
  .delta--hide { display: none !important; } }
@media only screen and (max-width: 720px) { .gamma--one-whole { width: 100%; }
  .gamma--one-half { width: 50%; }
  .gamma--one-third { width: 33.333%; }
  .gamma--two-thirds { width: 66.666%; }
  .gamma--one-quarter { width: 25%; }
  .gamma--three-quarters { width: 75%; }
  .gamma--one-fifth { width: 20%; }
  .gamma--two-fifths { width: 40%; }
  .gamma--three-fifths { width: 60%; }
  .gamma--four-fifths { width: 80%; }
  .gamma--one-sixth { width: 16.666%; }
  .gamma--five-sixths { width: 83.333%; }
  .gamma--one-seventh { width: 14.28571%; }
  .gamma--one-eighth { width: 12.5%; }
  .gamma--three-eighths { width: 37.5%; }
  .gamma--five-eighths { width: 62.5%; }
  .gamma--seven-eighths { width: 87.5%; }
  .gamma--one-ninth { width: 11.11111%; }
  .gamma--one-tenth { width: 10%; }
  .gamma--three-tenths { width: 30%; }
  .gamma--seven-tenths { width: 70%; }
  .gamma--nine-tenths { width: 90%; }
  .gamma--one-twelfth { width: 8.333%; }
  .gamma--five-twelfths { width: 41.666%; }
  .gamma--seven-twelfths { width: 58.333%; }
  .gamma--eleven-twelfths { width: 91.666%; }
  .gamma--hide { display: none !important; } }
@media only screen and (max-width: 530px) { .beta--one-whole { width: 100%; }
  .beta--one-half { width: 50%; }
  .beta--one-third { width: 33.333%; }
  .beta--two-thirds { width: 66.666%; }
  .beta--one-quarter { width: 25%; }
  .beta--three-quarters { width: 75%; }
  .beta--one-fifth { width: 20%; }
  .beta--two-fifths { width: 40%; }
  .beta--three-fifths { width: 60%; }
  .beta--four-fifths { width: 80%; }
  .beta--one-sixth { width: 16.666%; }
  .beta--five-sixths { width: 83.333%; }
  .beta--one-seventh { width: 14.28571%; }
  .beta--one-eighth { width: 12.5%; }
  .beta--three-eighths { width: 37.5%; }
  .beta--five-eighths { width: 62.5%; }
  .beta--seven-eighths { width: 87.5%; }
  .beta--one-ninth { width: 11.11111%; }
  .beta--one-tenth { width: 10%; }
  .beta--three-tenths { width: 30%; }
  .beta--seven-tenths { width: 70%; }
  .beta--nine-tenths { width: 90%; }
  .beta--one-twelfth { width: 8.333%; }
  .beta--five-twelfths { width: 41.666%; }
  .beta--seven-twelfths { width: 58.333%; }
  .beta--eleven-twelfths { width: 91.666%; }
  .beta--hide { display: none !important; } }
@media only screen and (max-width: 600px) { .palm--one-whole { width: 100%; }
  .palm--one-half { width: 50%; }
  .palm--one-third { width: 33.333%; }
  .palm--two-thirds { width: 66.666%; }
  .palm--one-quarter { width: 25%; }
  .palm--three-quarters { width: 75%; }
  .palm--one-fifth { width: 20%; }
  .palm--two-fifths { width: 40%; }
  .palm--three-fifths { width: 60%; }
  .palm--four-fifths { width: 80%; }
  .palm--one-sixth { width: 16.666%; }
  .palm--five-sixths { width: 83.333%; }
  .palm--one-seventh { width: 14.28571%; }
  .palm--one-eighth { width: 12.5%; }
  .palm--three-eighths { width: 37.5%; }
  .palm--five-eighths { width: 62.5%; }
  .palm--seven-eighths { width: 87.5%; }
  .palm--one-ninth { width: 11.11111%; }
  .palm--one-tenth { width: 10%; }
  .palm--three-tenths { width: 30%; }
  .palm--seven-tenths { width: 70%; }
  .palm--nine-tenths { width: 90%; }
  .palm--one-twelfth { width: 8.333%; }
  .palm--five-twelfths { width: 41.666%; }
  .palm--seven-twelfths { width: 58.333%; }
  .palm--eleven-twelfths { width: 91.666%; }
  .palm--hide { display: none !important; } }
@media only screen and (min-width: 1024px) { .desk--one-whole { width: 100%; }
  .desk--one-half { width: 50%; }
  .desk--one-third { width: 33.333%; }
  .desk--two-thirds { width: 66.666%; }
  .desk--one-quarter { width: 25%; }
  .desk--three-quarters { width: 75%; }
  .desk--one-fifth { width: 20%; }
  .desk--two-fifths { width: 40%; }
  .desk--three-fifths { width: 60%; }
  .desk--four-fifths { width: 80%; }
  .desk--one-sixth { width: 16.666%; }
  .desk--five-sixths { width: 83.333%; }
  .desk--one-seventh { width: 14.28571%; }
  .desk--one-eighth { width: 12.5%; }
  .desk--three-eighths { width: 37.5%; }
  .desk--five-eighths { width: 62.5%; }
  .desk--seven-eighths { width: 87.5%; }
  .desk--one-ninth { width: 11.11111%; }
  .desk--one-tenth { width: 10%; }
  .desk--three-tenths { width: 30%; }
  .desk--seven-tenths { width: 70%; }
  .desk--nine-tenths { width: 90%; }
  .desk--one-twelfth { width: 8.333%; }
  .desk--five-twelfths { width: 41.666%; }
  .desk--seven-twelfths { width: 58.333%; }
  .desk--eleven-twelfths { width: 91.666%; }
  .desk--hide { display: none !important; } }
.slider { position: relative; overflow: hidden; }
.slider .slide { position: absolute; width: 100%; opacity: 0; z-index: -1; top: 0; transform: translate3d(0, 0, 0); }
.slider .is-fading-in, .slider .slide.is-active, .slider .is-fading-out { transition: all .5s ease-in-out, z-index 0s ease; }
.slider .is-fading-in, .slider .slide.is-active { z-index: 2; position: relative; }
.slider .slide.is-active, .slider .is-fading-in { opacity: 1; }
.slider .is-fading-out { opacity: 1; }

/* ----------------------------------------------
	6. Components
---------------------------------------------- */
.banner { overflow: hidden; }

.banner__image { width: 100%; }

.banner__control { position: absolute; display: none; height: 43px; width: 29px; z-index: 13; top: 50%; left: 0px; margin-top: -21px; background: url(/user/themes/miu/plugins/slider-image/Front_images/arrows/arrows.simple.png) left top no-repeat; outline: none; cursor: pointer; }
.banner:hover .banner__control { display: block; }

.banner__control--right { right: 0px; left: auto; background: url(/user/themes/miu/plugins/slider-image/Front_images/arrows/arrows.simple.png) right top no-repeat; }

.bio { margin-bottom: 20px; }
.bio:after { content: ""; display: table; clear: both; }

.bio__year, .bio__item { float: left; margin-bottom: 10px; }
@media screen and (max-width: 530px) { .bio__year, .bio__item { float: none; } }

.bio__year { font-weight: 700; width: 10%; }
@media screen and (max-width: 530px) { .bio__year { width: 100%; margin-bottom: 0; } }

.bio__item { width: 90%; }
@media screen and (max-width: 530px) { .bio__item { width: 100%; } }

.huge_it_slideshow_dots_thumbnails_2 { display: none; }

body { background: #0d0d0d url("../../user/themes/miu/img/bg.gif") repeat; }

input, textarea { background-color: #000000; border: 1px solid #212121; color: #fff; padding: 3px; font-family: Arial, Helvetica, sans-serif; font-size: 11px; }

.content-entry ul, dl, p, table { font-family: Arial, Helvetica, sans-serif; }

input.wpcf7-submit { background-color: #fff; border: 1px solid #212121; color: #000; font-weight: bold; }

#container { max-width: 1000px; padding: 20px; margin: 0 auto; }

figure { padding: 20px 0; border-bottom: 1px solid #212121; position: relative; }

figure figcaption { position: absolute; top: 100px; left: 20px; font-family: 'PT Sans Narrow', sans-serif; font-size: 26px; text-shadow: 1px 1px #000; }

ul#sliderwrap, ul#sliderwrap li { list-style: none; margin: 0px; padding: 0px; }

#main { border-bottom: 1px solid #212121; min-height: 400px; }
@media screen and (min-width: 741px) { #main { display: flex; } }

#main section#primary { width: 100%; float: left; padding: 20px 0; }
@media screen and (min-width: 741px) { #main section#primary { width: 620px; } }

#main section#primary h2, #main section#primary h1 { color: #f47c30; font-family: 'PT Sans Narrow', sans-serif; font-size: 22px; }

#main section#primary h3 { color: #f47c30; font-family: 'PT Sans Narrow', sans-serif; font-size: 18px; }

#main section#primary article { margin-bottom: 40px; }

#main section#primary .coloumn { float: left; width: 280px; }

#main section#sidebar { width: 100%; float: left; min-height: 400px; padding: 20px; background-color: rgba(255, 255, 255, 0.05); }
@media screen and (min-width: 741px) { #main section#sidebar { width: 309px; margin-left: 30px; } }

#facebook { overflow: hidden; }

#main section#sidebar .widget { margin-bottom: 30px; }

#main section#sidebar h2 { color: #f47c30; font-family: 'PT Sans Narrow', sans-serif; font-size: 18px; }

#main section#sidebar h3, #main section#sidebar dl dt, #main section#sidebar dl dd { color: #fff; font-family: 'PT Sans Narrow', sans-serif; font-size: 16px; }

#main section#sidebar dl dt { float: left; display: block; clear: left; margin-bottom: 10px; }

#main section#sidebar dl dd { float: right; display: block; clear: right; margin-bottom: 10px; }

#main img.alignleft { float: left; margin: 0 20px 10px 0; }

#main section#sidebar dl { clear: both; }

footer { padding: 20px 0 0 0; }

footer p { float: right; }

.hotspot { color: #900; padding-bottom: 1px; cursor: pointer; width: 20px; }

#tt { position: absolute; display: block; }

#tttop { display: block; height: 2px; margin-left: 5px; overflow: hidden; }

#ttcont { padding: 40px 19px 17px 23px; margin-left: 7px; width: 160px; font-weight: bold; background-image: url(../../user/themes/miu/img/flame1.png); background-repeat: no-repeat; }

/*Change font color to suit*/
#ttbot { display: block; height: 7px; margin-left: 5px; overflow: hidden; }

#fm { width: 59px; height: 42px; background-image: url(../../user/themes/miu/img/flame1.png); background-repeat: no-repeat; margin-right: auto; margin-left: auto; margin-top: 0px; margin-bottom: 15px; }

#info { margin-top: 25px; }

#fm a { display: block; width: 59px; height: 42px; margin: 0 auto; text-indent: -9999px; }

img.find { margin: -17px 0 0 0; }

img.princess { float: right; width: 250px; height: 249px; margin: 0 0 0 10px; }

.border { border: 1px solid #212121; }

p.short { width: 350px; }

/* Prices table */
#prices table { font-size: 13px; color: #fff; }

.column1, .column4 { width: 150px; }

.column2, .column5 { width: 32px; }

.column3, .column6 { width: 90px; }

.strong.column1, .strong.column4 { height: 25px; }

/*	Added by Tony Barrett 28/1/13	*/
.semiPermMakeUp img { border: solid 2px #cfcfcf; }

.footerText { margin-top: 20px; float: left; }

.copyright { float: left; }

.gallery { margin: auto; }

.gallery .gallery-item { float: left; margin-top: 10px; text-align: center; width: 33%; clear: none !important; }

.gallery img { border: 2px solid #cfcfcf; }

.gallery .gallery-caption { margin-left: 0; }

iframe { max-width: 100%; }

.gallery__item { float: left; width: 33%; margin-bottom: 10px; padding-right: 10px; }
@media screen and (max-width: 530px) { .gallery__item { width: 50%; } }

.gallery__image { width: 100%; max-width: 150px; height: auto; }

.header-logo { display: inline-block; background: url("../../user/themes/miu/img/logo.png") no-repeat; width: 260px; height: 143px; margin-top: 0; margin-bottom: 20px; }
@media screen and (min-width: 841px) { .header-logo { float: left; } }

.header-logo__text { position: absolute; left: -999em; }

.header-nav-container { margin-bottom: 20px; }
@media screen and (min-width: 841px) { .header-nav-container { float: right; margin-top: 30px; max-height: 150px; } }

.header-nav, .header-nav__item { margin: 0px; padding: 0px; list-style: none; }

.header-nav__item { display: inline-block; }
@media screen and (max-width: 840px) { .header-nav__item { width: 33%; border-bottom: solid 1px rgba(255, 255, 255, 0.05); } }
@media screen and (max-width: 380px) { .header-nav__item { width: 50%; } }

.header-nav__link { display: block; color: #fff; text-align: center; font-family: 'PT Sans Narrow', sans-serif; font-size: 17px; padding: 15px 18px; min-width: 72px; }
.header-nav__link:hover { color: #f47c30; }
@media screen and (min-width: 841px) { .header-nav__link { padding: 30px 18px; } }

.header-nav__link.is-active { color: #fff; background: rgba(244, 124, 48, 0.3); }
@media screen and (min-width: 841px) { .header-nav__link.is-active { background: url("../../user/themes/miu/img/menu-bg-home.gif") center center no-repeat; } }

footer nav ul, footer nav li { margin: 0px; padding: 0px; list-style: none; }

footer nav li { float: left; }

footer nav li a { display: block; color: #fff; margin-right: 15px; font-family: PTSansNarrowRegular, Arial, sans-serif; }

footer nav li a:hover, footer nav li.current-menu-item a { color: #f47c30; }

.price-list__group { margin-bottom: 20px; }

.price-list__title { text-transform: uppercase; font-weight: 700; }

.price-list__title--large { font-size: 0.875rem; }

.price-list__item:after { content: ""; display: table; clear: both; }
.price-list__item:hover { background-color: rgba(255, 255, 255, 0.05); }

.price-list__type, .price-list__price { font-size: 12px; line-height: 1.6; }

.price-list__type { float: left; }

.price-list__price { float: right; }

/* ----------------------------------------------
	7-trumps
---------------------------------------------- */


