/*------------------------------------*\
    $CLEARFIX
\*------------------------------------*/
/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `.cf` class appearing over
 * and over in your markup.
 */

@mixin clearfix () {
    &:after{
        content:"";
        display:table;
        clear:both;
    }
}

.cf{
    @include clearfix;
}
