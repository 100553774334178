.gallery {

}

	.gallery__item {
		float: left;
		width: 33%;
		margin-bottom: $base-spacing-unit / 2;
		padding-right: $base-spacing-unit / 2;

		@include respond-max($beta) {
			width: 50%;
		}
	}

	.gallery__image {
		width: 100%;
		max-width: 150px;
		height: auto;
	}