.header-nav-container {
	margin-bottom: $base-spacing-unit;

	@include respond-min($delta) {
		float:right;
		margin-top: 30px; 
		max-height:150px;
	}
}

.header-nav, 
.header-nav__item {
	margin:0px;
	padding:0px;
	list-style:none;
}


.header-nav__item {
	display: inline-block;
	
	@include respond-max($delta) {
		width: 33%;
		border-bottom: solid 1px $palette--base-ui;
	}
	
	@include respond-max($alpha) {
		width: 50%;
	}
}

.header-nav__link{
	display:block;
	color:#fff;
	text-align:center;
	font-family:'PT Sans Narrow', sans-serif;
	font-size:17px;
	padding:15px 18px;
	min-width: 72px;

	&:hover{
		color:#f47c30;
	}
	
	@include respond-min($delta) {
		padding:30px 18px;
	}

}

.header-nav__link.is-active{
	color:#fff;
	background: rgba(#f47c30, .3);

	@include respond-min($delta) {
		background: url('../../user/themes/miu/img/menu-bg-home.gif') center center no-repeat;
	}
}