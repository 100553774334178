$price-list__font-size: 12px;
$price-list__line-height: 1 + strip-unit($price-list__font-size / $base-spacing-unit);

.price-list {

}
	
	.price-list__group {
		margin-bottom: $base-spacing-unit;
	}

		.price-list__title {
			text-transform: uppercase;
			font-weight: 700;
		}

		.price-list__title--large {
			font-size: rem(14px);
		}

		.price-list__item {
			@include clearfix;

			&:hover {
				background-color: rgba(255,255,255,.05);
			}
		}

			.price-list__type,
			.price-list__price {
				font-size: $price-list__font-size;
				line-height: $price-list__line-height;
			}

			.price-list__type {
				float: left;
			}

			.price-list__price {
				float: right;
			}

			.price-list__description {
				
			}