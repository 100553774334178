@mixin respond-min($width) {
	@media screen and (min-width: $width + 1px) {
		@content;
	}
}

@mixin respond-max($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin respond-between($width, $width2) {
	@media screen and (min-width:$width) and (max-width:$width2) {
		@content;
	}
}