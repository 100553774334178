footer nav ul, footer nav li{
	margin:0px;
	padding:0px;
	list-style:none;
}

footer nav li {
	float:left;
}

footer nav li a{
	display:block;
	color:#fff;
	margin-right:15px;
	font-family:PTSansNarrowRegular, Arial, sans-serif;
}

footer nav li a:hover, footer nav li.current-menu-item a{
	color:#f47c30;
}