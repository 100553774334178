$base-spacing-unit 				:20px;

$content-max-width              :700px;
$content-padding-x 				:85px;
$content-padding-y 				:60px;

$block-padding-x 				:$base-spacing-unit * 2;
$block-padding-y 				:$base-spacing-unit * 1.5; 

$group-margin 					:$base-spacing-unit; 
$group-margin--large 			:$base-spacing-unit * 2;
$group-margin--extralarge		:$base-spacing-unit * 3; 