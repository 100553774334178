.banner {
	overflow: hidden;
}

	.banner__image {
		width: 100%;
	}

	.banner__control {
        position: absolute;
        display: none;
        height:43px;
        width:29px;
        z-index: 13;
        top:50%;     
        left:0px;
        margin-top:-21px;
        background:url(/user/themes/miu/plugins/slider-image/Front_images/arrows/arrows.simple.png) left  top no-repeat; 
        outline: none;
        cursor: pointer;

        .banner:hover & {
			display: block
        }
	}

	.banner__control--right {
	    right:0px;
	    left: auto;
	    background:url(/user/themes/miu/plugins/slider-image/Front_images/arrows/arrows.simple.png) right top no-repeat; 
	}

