.header {
	@include respond-max($delta) {
		// text-align: center
	}
}

.header-logo {
	display: inline-block;
	background: url('../../user/themes/miu/img/logo.png') no-repeat;
	width:260px;
	height:143px;
	margin-top: 0;
	margin-bottom: $base-spacing-unit;

	@include respond-min($delta) {
		float:left;
	}
}

.header-logo__text {
	position:absolute;
	left:-999em;
}
